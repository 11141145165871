
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    min-height: calc(100vh - 48px);

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        position: relative;

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            color: #000;
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 999px !important;
            background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
            svg {
                path {
                    fill: #000;
                }
            }
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
            input,
            select {
                border-radius: 999px !important;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
                            rgb(51, 51, 51) 0px 0px 0px 2px;
                        font-weight: 600;
                    }
                }
                label {
                    border-radius: 999px;
                    // background-color: $atmmachineprices-pink;
                    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
                    // inear-gradient(to bottom,#f7dfa5,#f0c14b)

                    min-height: 50px;
                    margin: 3px;
                    color: #000;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @apply shadow-lg;
                    &:hover {
                        @apply shadow;
                        opacity: 0.8;
                    }
                    > span {
                        font-size: 16px !important;
                        margin-top: 0;
                    }
                    > div {
                        margin-right: 15px;
                        img {
                            width: 25px;
                            height: 25px;
                        }
                        & + span {
                            margin-top: 0;
                            margin-right: inherit;
                            width: auto;
                        }
                    }
                    & > span {
                        margin-right: auto;
                        width: 100%;
                    }
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 400px;
            width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
    }
    .image {
        z-index: 2;

        & > span {
            position: absolute !important;
            left: 0;
            bottom: 0;
            width: 100vw !important;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: 100% !important;
            height: auto !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
            max-height: unset !important;
            bottom: 0 !important;
            top: auto !important;

            @include lg {
                width: 100% !important;
                object-fit: cover !important;
            }
        }
    }

    &::before,
    &::after {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
    }
    &::before {
        background-image: url("/assets/images/background-7Opt.jpg");
        background-size: contain;
        background-repeat: repeat;
        z-index: 2;
    }
    &::after {
        background-color: rgba(black, 0.4);
        z-index: 3;
    }
}
